import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentCheckbox.figmaUrl.android} codeUrl={checklists.componentCheckbox.codeUrl.android} checklists={checklists.componentCheckbox.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Checkboxes allow users to select one or more items from a set. Checkboxes can turn an option on or off.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/android-1.png",
            "width": 250,
            "height": 250,
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The following is how to implement Checkbox component in Legion :`}</p>
    <h3><strong parentName="h3">{`Base Components`}</strong></h3>
    <p>{`The basic checkbox component is used for individual checkboxes or basic checkbox needs.`}</p>
    <p>{`To implement basic CheckBox we use this tag `}<inlineCode parentName="p">{`LgnPrimaryCheckBox`}</inlineCode>{` or `}<inlineCode parentName="p">{`LgnSecondaryCheckBox`}</inlineCode>{` .`}</p>
    <p>{`The following is usage to implement `}<inlineCode parentName="p">{`LgnPrimaryCheckBox`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.checkbox.LgnPrimaryCheckBox
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:layout_marginHorizontal="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    android:text="Sample Checkbox" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnPrimaryCheckBox(requiredContext()).apply {
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <p>{`The following is usage to implement `}<inlineCode parentName="p">{`LgnSecondaryCheckBox`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.checkbox.LgnSecondaryCheckBox
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:layout_marginHorizontal="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    android:text="Sample Checkbox" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnSecondaryCheckBox(requiredContext()).apply {
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <h3><strong parentName="h3">{`Checkbox Group`}</strong></h3>
    <p>{`Components for grouping checkboxes, to make it easier if you need checkbox options on your form.`}</p>
    <p>{`To implement basic CheckBox we use this tag `}<inlineCode parentName="p">{`LgnPrimaryCheckBoxGroup`}</inlineCode>{` or `}<inlineCode parentName="p">{`LgnSecondaryCheckBoxGroup`}</inlineCode>{` .`}</p>
    <p>{`The following is usage to implement `}<inlineCode parentName="p">{`LgnPrimaryCheckBoxGroup`}</inlineCode></p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.checkbox.LgnPrimaryCheckBoxGroup
    android:id="@+id/cgSample"
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:showDividers="middle" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnPrimaryCheckBoxGroup(requiredContext()).apply {
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <p>{`The following is usage to implement `}<inlineCode parentName="p">{`LgnSecondaryCheckBoxGroup`}</inlineCode></p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.checkbox.LgnSecondaryCheckBoxGroup
  android:id="@+id/cgSample2"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:showDividers="middle" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnSecondaryCheckBoxGroup(requiredContext()).apply {
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3><strong parentName="h3">{`Checkbox Container`}</strong></h3>
    <p>{`Container component containing checkbox groups and labels, helper and error text, this is used for consistency if you combine text fields in a form.`}</p>
    <p>{`To implement basic CheckBox we use this tag `}<inlineCode parentName="p">{`LgnPrimaryCheckBoxContainer`}</inlineCode>{` or `}<inlineCode parentName="p">{`LgnSecondaryCheckBoxContainer`}</inlineCode>{` .`}</p>
    <p>{`The following is usage to implement `}<inlineCode parentName="p">{`LgnPrimaryCheckBoxContainer`}</inlineCode></p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.checkbox.LgnPrimaryCheckBoxContainer
  android:id="@+id/cgcSample"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:hint="CheckBox Container"
  android:showDividers="middle"
  app:helperText="Ini CheckBox" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnPrimaryCheckBoxContainer(requiredContext()).apply {
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <p>{`The following is usage to implement `}<inlineCode parentName="p">{`LgnSecondaryCheckBoxContainer`}</inlineCode></p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.checkbox.LgnSecondaryCheckBoxContainer
  android:id="@+id/cgcSample2"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:hint="CheckBox Container"
  android:showDividers="middle"
  app:helperText="Ini CheckBox" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnSecondaryCheckBoxContainer(requiredContext()).apply {
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <h3><strong parentName="h3">{`Primary Color Checkbox`}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/android-2.png",
            "align": "center"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{`Secondary Color Checkbox`}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/android-3.png",
            "align": "center"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{`Primary Color Checkbox Group`}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/android-4.png",
            "align": "center"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{`Secondary Color Checkbox Group`}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/android-5.png",
            "align": "center"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{`Primary Color Checkbox Container`}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/android-6.png",
            "align": "center"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{`Secondary Color Checkbox Container`}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/android-7.png",
            "align": "center"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <h3><strong parentName="h3">{`Base Components Attributes`}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Text value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isEnable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable button directly via xml`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h3><strong parentName="h3">{`CheckBox Group Attributes`}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Show Divider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:showDividers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setShowDividers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show dividers between checkbox directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Checkbox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`addAll(List<String>)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To add checkbox programaticaly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Clear Checkbox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clear()`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To clear all checkbox programaticaly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checked State`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isChecked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To check checkbox ischecked or not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checked All State`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isCheckedAll`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To check checkbox is checked all or not, and you can set all checkbox in combo box group to checked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get and Set Checked Value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To get checked value from combo box or To set checked combo box, checkbox group can automaticaly find right combo box with given value and set to checked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Checked Listener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnCheckedChangeListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To add checked listener on checkbox`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h3><strong parentName="h3">{`CheckBox Container Attributes`}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Text value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Hint or Label value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Required Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set required status on text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set optional status on text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set helper text directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set error text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Show Divider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:showDividers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setShowDividers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show dividers between checkbox directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Checkbox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`addAll(List<String>)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To add checkbox programaticaly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Clear Checkbox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clear()`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To clear all checkbox programaticaly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checked State`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isChecked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To check checkbox ischecked or not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checked All State`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isCheckedAll`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To check checkbox is checked all or not, and you can set all checkbox in combo box group to checked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get and Set Checked Value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To get checked value from combo box or To set checked combo box, checkbox group can automaticaly find right combo box with given value and set to checked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Checked Listener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnCheckedChangeListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To add checked listener on checkbox`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      